<template>
  <div>
    <div ref="graph" :class="maximized ? 'max' : ''" class="network-graph-card card">
      <div v-if="!reduced" class="card-header">
        <div class="row">
          <div class="col-6">
            <h3 class="my-0">Netzwerk-Graph</h3>
          </div>
          <div class="col-6 text-right">
            <button @click="graphHidden = !graphHidden" class="btn btn-dark">
              {{ graphHidden ? 'Zeige' : 'Verstecke' }} Netzwerk-Graph
              <i class="fas" :class="graphHidden ? 'fa-caret-down' : 'fa-caret-up'"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="network-wrapper card-body p-0" v-if="!graphHidden">
        <button v-if="reduced" class="btn btn-primary maximize-button" @click="maximize">
          <i :class="maximized ? 'fa-window-minimize' : 'fa-window-maximize'" class="fad"></i>
        </button>
        <button v-if="!reduced || maximized" class="btn btn-primary screenshot-button" @click="takeScreenshot">
          <i class="fad" :class="screenshotLoading ? 'fa-circle-notch fa-spin' : 'fa-camera'"></i>
        </button>
        <button v-if="!reduced || maximized" class="btn btn-sm btn-default offset-button offset-button-right" @click="moveCanvas('x', 1)">
          <i class="fad fa-arrow-right"></i>
        </button>
        <button v-if="!reduced || maximized" class="btn btn-sm btn-default offset-button offset-button-left" @click="moveCanvas('x', -1)">
          <i class="fad fa-arrow-left"></i>
        </button>
        <button v-if="!reduced || maximized" class="btn btn-sm btn-default offset-button offset-button-up" @click="moveCanvas('y', -1)">
          <i class="fad fa-arrow-up"></i>
        </button>
        <button v-if="!reduced || maximized" class="btn btn-sm btn-default offset-button offset-button-down" @click="moveCanvas('y', 1)">
          <i class="fad fa-arrow-down"></i>
        </button>
        <button v-if="!reduced || maximized" class="btn btn-sm btn-default zoom-button zoom-button-in" @click="zoomCanvas(1)">
          <i class="fad fa-search-plus"></i>
        </button>
        <button v-if="!reduced || maximized" class="btn btn-sm btn-default zoom-button zoom-button-out" @click="zoomCanvas(-1)">
          <i class="fad fa-search-minus"></i>
        </button>

        <div class="network-graph"
             :class="nodes.length > 500 ? 'scroll-container-h scroll-container-w' : ''"
             :style="nodes.length > 500 ? 'height:calc(100vh - 5rem)' : 'height: 90vh' + ';max-height: 2000px'"
        >
          <d3-network
              ref='net'
              :net-nodes="mapNodes"
              :net-links="mapLinks"
              :options="options"
              :selection="selection"
              :link-cb="linkCallback"
              @node-click="nodeClick"
              @link-click="selectLink"
              @screen-shot="screenshotDone"
          />
        </div>
      </div>
      <div v-if="!reduced && (hide ? !hide : !graphHidden)" class="card-footer">
        <div class="row">
          <div class="col-sm-3 col-md-2">
            <label>Link Highlighting:</label>
          </div>
          <div class="col-sm-9 col-md-10">
            <div class="form-check form-check-inline" @click="focusIncoming = false">
              <input class="form-check-input" type="radio" name="linkHighlightOptions" id="linkHighlight2" :checked="!focusIncoming">
              <label class="form-check-label" for="linkHighlight2">Outgoing Links</label>
            </div>
            <div class="form-check form-check-inline" @click="focusIncoming = true">
              <input class="form-check-input" type="radio" name="linkHighlightOptions" id="linkHighlight1" :checked="focusIncoming">
              <label class="form-check-label" for="linkHighlight1">Incoming Links</label>
            </div>
            <button class="btn btn-sm btn-link"
                    @click="filterNodes({ key: (activeFilter.key === '' ? 'status' : ''), value: (activeFilter.value === '' ? 200 : '') })">
              Toggle failed Nodes
            </button>
            <button class="btn btn-sm btn-link" @click="toggleLabels">
              Toggle Node Labels
            </button>
          </div>
          <!--<div class="col-auto">
            <p class="mb-1">Select Nodes:</p>
          </div>
          <div class="col-auto">
            <button @click="queryNodes({ key: '', value: 'none'})" class="btn btn-sm btn-link">Reset</button>
            <button @click="selectNode(mapNodes[0])" class="btn btn-sm btn-link">Root</button>
            <button @click="queryNodes({ key: 'depth', value: 1 })" class="btn btn-sm btn-link">1st Level</button>
            <button @click="queryNodes({ key: 'depth', value: 2 })" class="btn btn-sm btn-link">2nd Level</button>
            <button @click="queryNodes({ key: 'depth', value: 3 })" class="btn btn-sm btn-link">3rd Level</button>
            <button @click="queryNodes({ key: 'status', value: 200 })" class="btn btn-sm btn-link">Status Code
              200</button>
            <button @click="queryNodes({ key: 'status', value: 404 })" class="btn btn-sm btn-link">Status Code
              404</button>
            <button @click="queryNodes({ key: 'status', value: 301 })" class="btn btn-sm btn-link">Status Code
              301</button>
            <button @click="queryNodes({ key: 'status', value: 'not scraped' })" class="btn btn-sm btn-link">Not
              Scraped</button>
          </div>
          <div class="w-100"></div>
          <div class="col-auto">
            <p class="mb-1">Filter Nodes:</p>
          </div>
          <div class="col-auto">
            <button @click="filterNodes({ key: '', value: 'all'})" class="btn btn-sm btn-link">Reset</button>
            <button @click="filterNodes({ key: 'depth', value: 0 })" class="btn btn-sm btn-link">Root</button>
            <button @click="filterNodes({ key: 'depth', value: 1 })" class="btn btn-sm btn-link">1st Level</button>
            <button @click="filterNodes({ key: 'depth', value: 2 })" class="btn btn-sm btn-link">2nd Level</button>
            <button @click="filterNodes({ key: 'depth', value: 3 })" class="btn btn-sm btn-link">3rd Level</button>
            <button @click="filterNodes({ key: 'status', value: 200 })" class="btn btn-sm btn-link">Status Code
              200</button>
            <button @click="filterNodes({ key: 'status', value: 404 })" class="btn btn-sm btn-link">Status Code
              404</button>
            <button @click="filterNodes({ key: 'status', value: 301 })" class="btn btn-sm btn-link">Status Code
              301</button>
            <button @click="filterNodes({ key: 'status', value: 'not scraped' })" class="btn btn-sm btn-link">Not
              Scraped</button>
          </div>-->
        </div>
      </div>
    </div>
    <svg style="height:0; margin:0; padding:0">
      <defs>
        <marker id="m-end" markerWidth="10" markerHeight="8" refX="20" refY="2.5" orient="auto" markerUnits="strokeWidth">
          <path d="M0,0 L0,5 L5,2.5 z"></path>
        </marker>
        <!--<marker id="m-start" markerWidth="8" markerHeight="4" refX="-10" refY="2" orient="auto" markerUnits="strokeWidth">
          <rect width="4" height="6"></rect>
        </marker>-->
        <marker id="m-end-selected-primary" markerWidth="10" markerHeight="8" refX="20" refY="2.5" orient="auto" markerUnits="strokeWidth">
          <path d="M0,0 L0,5 L5,2.5 z"></path>
        </marker>
        <!--<marker id="m-start-selected-primary" markerWidth="8" markerHeight="4" refX="-10" refY="2" orient="auto" markerUnits="strokeWidth">
          <rect width="4" height="6"></rect>
        </marker>-->
        <marker id="m-end-selected-secondary" markerWidth="10" markerHeight="8" refX="20" refY="2.5" orient="auto" markerUnits="strokeWidth">
          <path d="M0,0 L0,5 L5,2.5 z"></path>
        </marker>
        <!--<marker id="m-start-selected-secondary" markerWidth="8" markerHeight="4" refX="-10" refY="2" orient="auto" markerUnits="strokeWidth">
          <rect width="4" height="6"></rect>
        </marker>-->
      </defs>
    </svg>
  </div>
</template>

<script>
import D3Network from 'vue-d3-network'

export default {
  name: 'NetworkGraph',
  components: {
    D3Network
  },
  directives: {},
  props: {
    nodes: Array,
    links: Array,
    selectedNode: Object,
    hide: Boolean,
    reduced: Boolean,
    hideLabels: Boolean
  },
  data() {
    return {
      maximized: false,
      graphHidden: false,
      nodesViewLimit: 50,
      forceSlider: 40,
      focusIncoming: false,
      screenshotLoading: false,
      nodeSize: [
        {
          _cssClass: 'depth-0',
          _size: 40,
        },
        {
          _cssClass: 'depth-1',
          _size: 20
        },
        {
          _cssClass: 'depth-2',
          _size: 10
        },
        {
          _cssClass: 'depth-3',
          _size: 5
        }
      ],
      nodeColor: {
        "404": {
          _color: "#832500"
        },
        "200": {
          _color: "#333333"
        },
        "other": {
          _color: "#b8b8b8"
        },
      },
      options: {
        force: 2000,
        nodeSize: 3,
        nodeLabels: false,
        linkLabels: true,
        canvas: false,
        fontSize: 12,
        offset: {
          x: 0,
          y: 0
        },
        size: {
          w: null,
          h: null
        }
      },
      activeQuery: {
        key: '',
        value: ''
      },
      activeFilter: {
        key: '',
        value: ''
      },
      manualSelection: {
        nodes: {},
        links: {}
      },
    }
  },
  computed: {
    rootUrl() {
      return this.project.profile.rootUrl;
    },
    map() {
      let nodes = this.nodes;
      nodes = nodes
          .filter(node => {
            return this.activeFilter.key === '' || node[this.activeFilter.key] === this.activeFilter.value;
          })
          .map(node => {
            return Object.assign(node, {
              name: node.title,
              selected: !!this.connectedNodes.find(n => n.id === node.id),
              ...this.nodeSize[node.depth],
              ...node._color ? {_color: node._color} : this.nodeColor[node.status] || this.nodeColor["other"],
            });
          });

      let links = this.links;
      links = links
          .filter(link => {
            return this.activeFilter.key === '' || (nodes.find(n => n.id === link.sid) && nodes.find(n => n.id === link.tid));
          })
          .map(link => {
            link.selected = !!this.connectedLinks.find(l => l.id === link.id);
            link.direction = this.incomingLinks.find(l => l.id === link.id) ? 'in' : 'out';
            return link;
          });

      return {nodes, links};
    },
    mapNodes() {
      return this.map.nodes;
    },
    mapLinks() {
      return this.map.links;
    },
    connectedLinks() {
      return this.selectedNode ? this.links.filter(l => l.sid === this.selectedNode.id || l.tid === this.selectedNode.id) : [];
    },
    outgoingLinks() {
      return this.connectedLinks.filter(l => l.sid === this.selectedNode.id);
    },
    incomingLinks() {
      return this.connectedLinks.filter(l => l.tid === this.selectedNode.id);
    },
    connectedNodes() {
      return this.selectedNode ? this.nodes.filter(n => this.connectedLinks.find(l => l.tid === n.id)) : [];
    },
    selection() {
      if (!this.selectedNode) {
        return { nodes: {}, links: {} };
      }

      let nodes = {};
      for (let node of this.connectedNodes) {
        nodes[node.id] = node;
      }
      let links = {};
      for (let link of this.connectedLinks) {
        links[link.id] = link;
      }
      return { nodes, links, root: this.selectedNode };
    },
  },
  watch: {
    selectedNode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectNode(newVal);
      }
    },
    focusIncoming() {
      for (let link of this.links) {
        this.linkCallback(link);
      }
    },
  },
  methods: {
    maximize() {
      this.maximized = !this.maximized;
      setTimeout(() => {
        let frame = window.requestAnimationFrame(() => {
          let graphEl = this.$refs.graph // document.querySelector(".network-graph-card");
          this.options.size.w = graphEl.clientWidth;
          this.options.size.h = graphEl.clientHeight;
          this.zoomCanvas(-1);
          window.cancelAnimationFrame(frame);
        });
      });
    },
    toggleLabels() {
      let options = this.options;
      options.nodeLabels = !this.options.nodeLabels;
      this.options = Object.assign({}, options);
    },
    nodeClick($event, node) {
      if (this.tool === 'pin') {
        this.pinNode(node);
      } else {
        this.selectNode(node);
        this.$emit('selectNode', node);
      }
    },
    selectNode(node) {
      if (!node || this.selectedNode === node) {
        this.manualSelection = { nodes: {}, links: {} };
        this.graphHidden = false;
      } else {
        this.manualSelection = {
          nodes: this.connectedNodes,
          links: this.connectedLinks,
          root: node
        };
        this.manualSelection.nodes[node.id] = node;
      }
    },
    pinNode (node) {
      if (node.pinned) {
        node.pinned = false;
        node.fx = null;
        node.fy = null;
      } else {
        node.pinned = true;
        node.fx = node.x;
        node.fy = node.y;
      }
      this.nodes[node.index] = node;
    },
    selectLink(event, link) {
      this.$emit('selectLink', link);
      if (this.manualSelection.links[link.id]) {
        this.manualSelection = { nodes: {}, links: {} };
      } else {
        this.manualSelection = { nodes: {}, links: {} };
        link._color = "rgba(12, 201, 219, 1)"
        this.manualSelection.links[link.id] = link;

        for (let node of this.nodes.filter(n => n.id === link.sid || n.id === link.tid)) {
          this.manualSelection.nodes[node.id] = node;
        }
      }
    },
    linkCallback(link) {
      if (link.selected) {
        let isFocused = this.focusIncoming ^ link.direction === 'out';
        link._color = isFocused ? "rgba(12, 201, 219, .8)" : "rgba(12, 201, 219, .2)";
        let marker = isFocused ? 'primary' : 'secondary';
        link._svgAttrs = {
          'stroke-width': '3px',
          'marker-end': 'url(#m-end-selected-' + marker + ')',
          'marker-start': 'url(#m-start-selected-' + marker + ')'
        }
      } else {
        link._color = link._resetColor;
        link._svgAttrs = {
          'marker-end': 'url(#m-end)',
          'marker-start': 'url(#m-start)'
        }
      }
      return link;
    },
    queryNodes(query) {
      this.manualSelection = { nodes: {}, links: {} };
      this.activeFilter = {
        key: '',
        value: ''
      };
      this.activeQuery = query;
    },
    filterNodes(filter) {
      this.manualSelection = { nodes: {}, links: {} };
      this.activeQuery = {
        key: '',
        value: ''
      };
      this.activeFilter = filter;
    },
    moveCanvas(axis, dir) {
      let options = this.options;
      options.offset[axis] = options.offset[axis] + (dir * 75);
      this.options = Object.assign({}, options);
    },
    zoomCanvas(dir) {
      this.forceSlider += (dir * 10)
      let options = this.options;

      let minpos = 1;
      let maxpos = 100;
      let minlval = Math.log(300);
      let maxlval = Math.log(30000);
      let scale = (maxlval - minlval) / (maxpos - minpos);

      options.force = Math.exp((this.forceSlider - minpos) * scale + minlval);
      options.fontSize += dir * 2 / 3;

      this.options = Object.assign({}, options)
    },
    takeScreenshot() {
      this.screenshotLoading = true;
      setTimeout(() => {
        this.$refs.net.screenShot("Web Strategy Toolbox Link Tree.svg", "#ffffff", true)
      }, 100);
    },
    screenshotDone() {
      console.info("Screenshot done.")
      this.screenshotLoading = false;
    }
  },
  beforeMount() {
    if (this.nodes.length > 200) {
      this.forceSlider = 10;
      this.options.force = 400;
    } else if (this.nodes.length > 500) {
      this.forceSlider = 9;
      this.options.force = 300;
    }
    this.options.nodeLabels = this.nodes.length < 200;

    if (this.$route.params.id || this.hide) {
      this.graphHidden = true;
    }
  },
}
</script>


<style lang="scss">
.network-graph-card {
  &.max {
    position: fixed;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    z-index: 5001;
    box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, .2);
  }
}

canvas {
  position: absolute;
  top: 0;
  left: 0
}

.network-wrapper {
  position: relative;
  $offset: .5rem;

  .network-graph {
    .net {
      height: 100%;
      margin: 0
    }
  }

  .screenshot-button, .maximize-button, .zoom-button {
    position: absolute;
    top: $offset;
    right: $offset;
    height: 2rem;
    width: 2rem;
    padding: 0;
    text-align: center;
  }

  .maximize-button {
    right: unset;
    left: $offset;
  }

  .zoom-button {
    top: unset;
    right: unset;
    bottom: $offset;

    &-in {
      right: $offset;
    }

    &-out {
      left: $offset;
    }
  }

    .offset-button {
      position: absolute;
      color: var(--primary);
      background: rgba(200,200,200,.2);

      $length: calc(100% - 6rem);

      &-left, &-right {
        top: 50%;
        height: $length;
        transform: translateY(-50%);
      }
      &-left { left: $offset; }
      &-right { right: $offset; }
      &-up, &-down {
        left: 50%;
        width: $length;
        transform: translateX(-50%);
      }
      &-up { top: $offset; }
      &-down { bottom: $offset; }
    }
  }

  .node {
    stroke-width: 0;
    stroke: #333333;
    -webkit-transition: r .2s;
    transition: r .2s;
    fill: #555555;
    cursor: pointer;
  }
  .node.selected {
    stroke-width: 3px;
    stroke: rgb(12, 201, 219)
  }
  .node.pinned {
    stroke: rgba(0,0,0,.02)
  }
  .node-label {
    pointer-events: none;
  }
  .link {
    stroke-width: 2px;
    stroke: rgba(0,0,0,.1);
  }
  .link,.node {
    stroke-linecap:round
  }
  .link:hover {
    stroke-width: 5px;
  }
  .node.depth-0:hover {
    r: 30;
  }
  .node.depth-1:hover {
    r: 20;
  }
  .node.depth-2:hover {
    r: 12;
  }
  .link.selected {
    stroke-width: 3px;
    stroke: rgba(12, 201, 219, .8)
  }
  .curve {
    fill:none
  }
  .link-label,.node-label {
    fill: #111111
  }
  .node-label.text-muted {
    opacity: .75;
  }
  .link-label {
    -webkit-transform: translateY(-.5em);
    text-anchor:middle
  }
  #m-end path, #m-start {
    fill: rgba(0,0,0,.1);
  }
  #m-end-selected-primary path, #m-start-selected-primary {
    fill: rgba(12, 201, 219, .8);
  }
  #m-end-selected-secondary path, #m-start-selected-secondary {
    fill: rgba(12, 201, 219, .3);
  }
</style>